import axios from "@/utils/request";

// 获取福利列表
export function queryAnnouMsgList(data) {
    return axios.get(`annou/msg/list`, {
        params: data
    })
}

// 查询福利详情
export function queryAnnougetById(data) {
    return axios.get(`/annou/msg/getById`,{
        params: data
    })
}
